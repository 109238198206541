import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "type-aliases"
    }}>{`Type Aliases`}</h1>
    <hr></hr>
    <p>{`Sometimes your type annotations become really long, so you need some mechanisms which help you not repeat yourself. And prevent "copy-paste" errors.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const user: {
  email: string;
  password: string;
} = { email: "test.email@gmail.com", password: "qwerty" };

const copyOfOriginalUser: {
  email: string;
  password: string;
} = user;

//-------------------

function createResponse(
  status: "Success" | "Failed"
): { status: "Success" | "Failed" } {
  return { status };
}
`}</code></pre>
    <p>{`One of them is type alisases. Type aliases create a new name for a type.
Type aliases can be created using the keyword `}<inlineCode parentName="p">{`type`}</inlineCode>{` followed by its name, an equals sign `}<inlineCode parentName="p">{`=`}</inlineCode>{`, and a type definition. You can place any valid type definition after equals sign `}<inlineCode parentName="p">{`=`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Type alias for object type
type User = {
  email: string;
  password: string;
};

const user: User = { email: "test.email@gmail.com", password: "qwerty" };
const copyOfOriginalUser: User = user;

//-------------------

// Type alias for union type
type Status = "Success" | "Failed";

function createResponse(status: Status): { status: Status } {
  return { status };
}
`}</code></pre>
    <p>{`Aliasing doesn’t create a new type - it creates a new name to refer to source type.`}</p>
    <h2 {...{
      "id": "interfaces"
    }}>{`Interfaces`}</h2>
    <p>{`Also you can use `}<inlineCode parentName="p">{`implements`}</inlineCode>{` with object type aliases for `}<a parentName="p" {...{
        "href": "/docs/class-types"
      }}>{`Class Types`}</a>{` to tell Hegel that you want class to match "protocol".`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type JsonSerializable = {
  toJSON: () => Object,
  ...
};

class User implements JsonSerializable {
  email: string;
  password: string;

  constructor(email, password) {
    this.email = email;
    this.password = password;
  }

  toJSON() {
    return {
      email: this.email,
      password: this.password,
      _type: "User"
    };
  }
}

const json: JsonSerializable = new User("test@gmail.com", "qwerty");
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Note, that your object type which should act like interface should be a soft object type.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      